









import { Observer } from 'mobx-vue'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class DaoLabel extends Vue {
  @Prop({}) dao!: any

  gotoDaoDetail() {
    this.$router.push(`/community-organize/dao-detail/${this.dao.id}`)
  }
}
