import { render, staticRenderFns } from "./dao-label.vue?vue&type=template&id=579beea9&scoped=true&"
import script from "./dao-label.vue?vue&type=script&lang=ts&"
export * from "./dao-label.vue?vue&type=script&lang=ts&"
import style0 from "./dao-label.vue?vue&type=style&index=0&id=579beea9&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "579beea9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAvatar,VImg})
